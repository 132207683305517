<template>
  <BaseDialog
    width="420px"
    :title="displayData.paymentName"
    hideCancel
    @confirm="onConfirm"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem>
        <div class="flex flex-col">
          <span>使用方式</span>
          <BaseElRadioGroup v-model="formData.payAll" @change="onPayTypeChange">
            <BaseElRadio :label="true">全額金額</BaseElRadio>
            <BaseElRadio :label="false">部分金額</BaseElRadio>
          </BaseElRadioGroup>
        </div>
      </BaseElFormItem>

      <BaseElFormItem prop="amount">
        <div class="flex flex-col">
          <span>折抵</span>
          <BaseElInput
            v-model.number="formData.amount"
            style="width: 100%"
            type="number"
            class="w-full"
            placeholder="請輸入金額"
            @change="
              formData.amount < paymentList.left
                ? (formData.payAll = false)
                : (formData.payAll = true)
            "
          />
        </div>
      </BaseElFormItem>

      <BaseElFormItem v-if="showControl.balanceLeft">
        <div class="flex flex-col">
          <span>餘額</span>
          <span>{{ displayData.balanceLeft }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { maxNumberRules, minRules, noEmptyRules, rangeRules } from '@/validation'
import { get } from 'lodash'
import { computed, defineComponent, onMounted } from 'vue'
export default defineComponent({
  name: 'SalesRecordCreatePaymentUseModal',
  components: { BaseDialog },
  props: {
    selectPayment: {
      type: Object,
      default: () => ({}),
    },
    member: {
      type: Object,
      default: () => ({}),
    },
    paymentList: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    const formRules = computed(() => {
      const balance = get(props.member, 'Wallet.balance')

      let max = props.paymentList.left
      let maxNumMessage

      if (get(props.selectPayment, 'type') === 'wallet') {
        if (balance < props.paymentList.left) {
          max = balance
        }
        if (formData.amount > max) {
          maxNumMessage = '餘額不足'
        }
      }

      const rules = {
        amount: [noEmptyRules(), minRules(1), maxNumberRules(max, maxNumMessage)],
      }
      return rules
    })

    initFormData({
      payAll: true,
      amount: 0,
    })

    const displayData = computed(() => {
      return {
        paymentName: get(props.selectPayment, 'name'),
        // 餘額
        balanceLeft: get(props.member, 'Wallet.balance') - formData.amount,
      }
    })

    const showControl = computed(() => {
      const control = {
        balanceLeft: get(props.selectPayment, 'type') === 'wallet',
      }
      return control
    })

    const onPayTypeChange = (isAll) => {
      if (get(props.selectPayment, 'type') === 'wallet') {
        if (isAll && props.paymentList.left <= props.member.Wallet.balance) {
          formData.amount = props.paymentList.left
        } else {
          formData.amount = props.member.Wallet.balance
        }
      } else {
        if (isAll) formData.amount = props.paymentList.left
      }

      if (!isAll) formData.amount = 0
    }

    const onConfirm = async () => {
      if (!await checkForm(formRef.value)) return

      emit('confirm', formData)
    }

    onMounted(() => {
      onPayTypeChange(true)
    })

    return {
      displayData,
      formRef,
      formData,
      formRules,
      showControl,
      onConfirm,
      onPayTypeChange,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
