<template>
  <BaseDialog
    :title="displayData.title"
    width="610px"
    hideCancel
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem label="自定義優惠金額" prop="amount">
        <BaseElInput v-model.number="formData.amount" />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { minRules, noEmptyRules } from '@/validation'
import { get } from 'lodash'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'SalesRecordCreateCustomDiscountUseModal',
  components: { BaseDialog },
  props: {
    selectDiscount: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formData, formRef, initFormData, checkForm } = useBaseForm()

    initFormData({
      amount: 0,
    })

    const formRules = computed(() => {
      const rules = {
        amount: [
          noEmptyRules(),
          minRules(1),
        ],
      }
      return rules
    })

    const displayData = computed(() => {
      const data = {
        title: get(props.selectDiscount, 'name'),
      }
      return data
    })

    const onConfirm = async () => {
      if (!await checkForm(formRef.value)) return
      emit('confirm', formData)
    }

    return { formData, formRef, formRules, displayData, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>

</style>
