import { render, staticRenderFns } from "./SalesRecordCreatePaymentUseModal.vue?vue&type=template&id=dff4bbd6&scoped=true"
import script from "./SalesRecordCreatePaymentUseModal.vue?vue&type=script&lang=js"
export * from "./SalesRecordCreatePaymentUseModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff4bbd6",
  null
  
)

export default component.exports